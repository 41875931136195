import {
	createRouter,
	createWebHistory,
	createWebHashHistory,
	ErrorHandler
} from 'vue-router'

// // 重加载push方法，解决报错
// const originalPush = createRouter.prototype.push
// createRouter.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch(err => err)
// }
const routes = [{
		path: '/',
		name: 'Home',
		component: () => import("@/views/home"),
		meta: {
			title: '起零WEB服务平台 - ISTERO WEB SERVICE'
		}
	}, {
		path: '/tools/twitter',
		name: 'twitter',
		component: () => import("@/views/tools/twitter"),
		meta: {
			title: 'Twitter视频下载工具 - 起零WEB服务平台'
		}
	}, {
		path: '/tools/instagram',
		name: 'instagram',
		component: () => import("@/views/tools/instagram"),
		meta: {
			title: 'Instagram视频下载工具 - 起零WEB服务平台'
		}
	}, {
		path: '/tools/tiktok',
		name: 'tiktok',
		component: () => import("@/views/tools/tiktok"),
		meta: {
			title: 'Instagram视频下载工具 - 起零WEB服务平台'
		}
	}, {
		path: '/404',
		name: '404',
		component: () => import("@/views/404"),
		meta: {
			title: '404 - 起零WEB服务平台 - ISTERO WEB SERVICE'
		}
	},
	{
		path: '/:pathMatch(.*)',
		redirect: '/404',
		hidden: true
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	window.document.title = to.meta.title
	next()
})


export default router
