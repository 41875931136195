<template>
	<tools type="1" :urls="url"></tools>
</template>
<script>
	import tools from './compontents/tools'
	import {
		useRouter
	} from "vue-router";
	export default {
		name: 'instagram',
		components: {
			tools
		},
		data() {
			return {
				url: null
			}
		},
		created() {
		  let that = this
			if (this.$router.currentRoute.value.query.url) {
				that.url = this.$router.currentRoute.value.query.url
			}
		}
	}
</script>
