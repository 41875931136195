<template>
	<div class="header">
		<div class="c">
			<div class="logo">
				<img src="https://s.istero.com/images/logo.png" />
			</div>
			<div class="else">
				<el-button type="" :size="size" @click="openIstero">访问官网</el-button>
				<el-button type="" :size="size" @click="openapi">起零API服务</el-button>
				<el-button type="primary" :size="size" @click="feedBack">在线反馈</el-button>
			</div>
		</div>
	</div>
	<div class="mBox">
		<div style="text-align: center;">
			<div class="title">ISTERO WEB SERVICE
			</div>
			<div class="desc">起零WEB服务平台</div>
		</div>
		<el-row :gutter="20">
			<el-col :span="gutter">
				<el-card class="box-card" shadow="never">
					<div class="title">X Video Downloader</div>
					<div class="desc">下载Twitter / X视频</div>
					<el-button size="large" type="primary" @click="goTwitter">立即使用</el-button>
				</el-card>
			</el-col>
			<el-col :span="gutter">
				<el-card class="box-card" shadow="never">
					<div class="title">Instagram Source Downloader</div>
					<div class="desc">下载Ins视频/图片等资源</div>
					<el-button size="large" type="primary" @click="goInstagram">立即使用</el-button>
				</el-card>
			</el-col>
			<el-col :span="gutter">
				<el-card class="box-card" shadow="never">
					<div class="title">TikTok Video Downloader</div>
					<div class="desc">下载TikTok无水印视频</div>
					<el-button size="large" type="primary" @click="goTiktok">立即使用</el-button>
				</el-card>
			</el-col>
			<el-col :span="gutter">
				<el-card class="box-card" shadow="never">
					<div class="title">Will Comming</div>
					<div class="desc">更多服务即将上线</div>
					<el-button size="large">敬请期待</el-button>
				</el-card>
			</el-col>
		</el-row>
		<div class="footer" v-if="!mobile">
			<el-divider content-position="center">
				<div>Copyright © 2023 - 2025 ISTERO. All Rights Reserved.</div>
				<div class="cLink"><a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备19003788号-1</a></div>
			</el-divider>
		</div>
		<div v-else class="footer">
			<div>Copyright © 2023 - 2025 ISTERO. All Rights Reserved.</div>
			<div class="cLink"><a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备19003788号-1</a></div>
		</div>
		<el-dialog v-model="dialogFormVisible" title="提交反馈" :width="mobile ? '95%' : '40%'">
			<el-form :model="form">
				<el-form-item label="选择反馈的类型" :label-width="140">
					<el-select v-model="form.type" placeholder="请选择反馈的类型">
						<el-option :label="item.label" :value="item.value" v-for="(item,index) in Data" />
					</el-select>
				</el-form-item>
				<el-form-item label="请输入反馈的内容">
					<el-input v-model="form.content" type="textarea" autocomplete="off" :rows="8"
						placeholder="请输入详细的反馈内容" />
				</el-form-item>
			</el-form>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取消</el-button>
					<el-button type="primary" @click="postFeedBack">
						提交
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import {
		showToast
	} from 'vant';
	import {
		watch,
		ref,
		onMounted,
		onUpdated,
		reactive
	} from 'vue';
	import {
		useWindowSize
	} from '@vant/use';
	import {
		ElMessage,
		ElNotification,
		ElMessageBox
	} from 'element-plus'
	import {
		feedback
	} from '@/api/home';
	const dialogVisible = ref(false)

	export default {
		name: 'Home',
		data() {
			return {
				form: {
					type: '',
					content: '',
				},
				Data: [{
					"label": "BUG反馈",
					value: 1
				}, {
					"label": "功能建议",
					value: 2
				}, {
					"label": "失效反馈",
					value: 3
				}, {
					"label": "其他反馈",
					value: 4
				}],
				dialogFormVisible: false,
			}
		},
		setup() {
			let size = "large";
			let gutter = 12;
			let mobile = false;
			const screen = reactive({
				height: null,
				width: null,
			});
			const {
				width,
				height
			} = useWindowSize();
			updateWindoiw(width, height);

			function updateWindoiw(width, height) {
				screen.height = height._value;
				screen.width = width._value;
				if (screen.width >= 750) {
					size = 'large'
					gutter = 12
					mobile = false
				} else {
					size = 'small'
					gutter = 24
					mobile = true
				}
			}
			return {
				size,
				gutter,
				mobile
			}

		},
		methods: {
			openapi() {
				window.open("https://api.istero.com");
			},
			feedBack() {
				this.dialogFormVisible = true
			},
			postFeedBack() {
				let that = this
				if (!that.form.type) {
					ElMessage({
						message: "反馈类型不能为空",
						type: "warning",
						duration: 1500
					})
					return false;
				} else if (!that.form.type) {
					ElMessage({
						message: "反馈类型不能为空",
						type: "warning",
						duration: 1500
					})
					return false;
				} else {
					feedback({
						type: that.form.type,
						content: that.form.content
					}).then(res => {
						if (res.code == 200) {
							ElMessage({
								message: "反馈成功，我们会尽快查看",
								type: "success",
								duration: 1500
							})
							that.form.type = ""
							that.form.content = ""
							that.dialogFormVisible = false
						} else {
							ElMessage({
								message: res.message,
								type: "warning",
								duration: 1500
							})
						}
					})
				}
			},
			goTwitter() {
				this.$router.push({
					path: "/tools/twitter"
				})
			},
			goInstagram() {
				ElMessage({
					message: "功能维护中，暂不可用",
					type: "warning",
					duration: 1500
				})
				return false
				this.$router.push({
					path: "/tools/instagram"
				})
			},
			goTiktok() {
				ElMessage({
					message: "功能维护中，暂不可用",
					type: "warning",
					duration: 1500
				})
				return false
				this.$router.push({
					path: "/tools/tiktok"
				})
			},
			openIstero() {
				window.open("https://www.istero.com")
			}
		}
	}
</script>
<style>
	body {
		background: #f5f5f5;
		margin: 0 0;
		padding: 0 0;
	}


	.header {
		background: #ffffff;
		width: 100%;
		border-bottom: 1px solid #ededed;
		padding: 10px 0px
	}

	.header .c {
		display: flex;
		justify-content: space-between;
		padding: 5px;
		align-items: center;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	.header .c .logo img {
		width: 60px;
		height: auto;
	}

	.mBox .title {
		font-weight: 600;
		font-family: "Source Sans Pro";
		color: #2a2a2a;
		font-size: 40px;
		letter-spacing: -1px;
	}

	.mBox .desc {
		color: #6d6d6d;
		font-size: 25px;
		margin-top: 10px;
		letter-spacing: 3px;
		margin-bottom: 60px;
	}


	.mBox {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
	}

	.mBox .box-card {
		text-align: center;
		padding: 60px 0px;
		margin-bottom: 20px;
	}

	.mBox .box-card .title {
		font-size: 32px;
	}

	.mBox .box-card .desc {
		font-size: 23px;
		margin-top: 10px;
		color: #666666;
		margin-bottom: 40px;
	}

	.el-divider__text {
		background: #f5f5f5;
		color: #909090;
	}


	.footer {
		padding: 30px 0px;
		font-size: 20px;
		text-align: center;
		color: #909090;
	}


	.footer .cLink {
		margin-top: 5px;
	}

	.footer .cLink a {
		color: #909090;
		text-decoration: none;
		font-size: 0.9rem;
	}

	@media screen and (min-width: 750px) {
		.header {
			background: #ffffff;
			width: 100%;
			border-bottom: 1px solid #ededed;
		}

		.header .c {
			display: flex;
			justify-content: space-between;
			padding: 5px;
			align-items: center;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		.header .c .logo img {
			width: 50px;
			height: auto;
		}

		.mBox .title {
			font-weight: 600;
			font-family: "Source Sans Pro";
			color: #2a2a2a;
			font-size: 40px;
			letter-spacing: -1px;
		}

		.mBox .desc {
			color: #6d6d6d;
			font-size: 22px;
			margin-top: 8px;
			letter-spacing: 3px;
			margin-bottom: 60px;
		}


		.mBox {
			width: 70%;
			margin-left: auto;
			margin-right: auto;
			margin-top: 80px;
		}

		.mBox .box-card {
			text-align: center;
			padding: 60px 0px;
			margin-bottom: 20px;
			height: 180px;
		}

		.mBox .box-card .title {
			font-size: 30px;
		}

		.mBox .box-card .desc {
			font-size: 15px;
			margin-top: 10px;
			color: #666666;
			margin-bottom: 20px;
		}

		.el-divider__text {
			background: #f5f5f5;
			color: #909090;
		}

		.footer {
			padding: 80px 0px;
		}

	}
</style>