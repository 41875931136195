<template>
	<div>
		<div class="app-container">
			<el-row>

				<el-col :span="12">
					<img :src="img_404" alt="404" class="img-style">
				</el-col>
				<el-col :span="12">
					<div style="margin-left: 100px;margin-top: 60px;text-align: left">
						<h1 class="color-main">OOPS!</h1>
						<h2 style="color: #606266">页面不小心迷路了</h2>
						<div style="color:#909399;font-size: 14px">请检查您输入的网址是否正确，点击以下按钮可返回主页。</div>
						<el-button style="margin-top: 20px" type="primary" size="large" round @click="handleGoMain">返回首页</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	import img_404 from '@/assets/404.png';
	export default {
		name: 'wrongPage',
		data() {
			return {
				img_404
			}
		},
		methods: {
			handleGoMain() {
				this.$router.push({
					path: '/'
				})
			}
		},
	}
</script>

<style scoped>
	.app-container {
		width: 80%;
		margin: 25vh auto;
		text-align: center
	}

	.img-style {
		width: 100%;
		height: auto;
		margin-left: auto;
		margin-right: auto
	}
</style>
